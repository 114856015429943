import Icon from '@/components/atoms/Icon'
import {useWordPressContext} from '@/components/common/WordPressProvider'
import AddToCart from '@/components/molecules/AddToCartButton/AddToCartButton'
import {Course} from '@/components/types/course'
import {lg} from '@/config'
import useMediaQuery from '@/functions/useMediaQuery'
import cn from 'classnames'
import {useTranslation} from 'next-i18next'
import Link from 'next/link'
import {useEffect, useRef, useState} from 'react'
import SubtitleWithDot from '../SubtitleWithDot'
import styles from './ProgramsTable.module.scss'

type Props = {
  courses: Course[]
  hasVariations?: boolean
  showMore: boolean
  defaultShown: number
  isCheckout?: boolean
}

export default function ProgramsTable({
  courses,
  hasVariations,
  showMore,
  defaultShown,
  isCheckout
}: Props) {
  const rowRefs = useRef<(HTMLTableRowElement | null)[]>([])
  const [isTabChange, setIsTabChange] = useState(false)
  const {t} = useTranslation(['global', 'programm'])
  const isLg = useMediaQuery(lg)
  const replaceDash = isLg ? '-<br>' : '-'
  const {post}: any = useWordPressContext()
  const [isSafariBrowser, setIsSafariBrowser] = useState(false)

  const isAllPaDates =
    courses?.[0]?.__typename !== 'Course' &&
    courses?.every((course) => course?.pa_dates && course?.pa_dates?.length > 0)
  const isAllPaBookingTimeSlot =
    courses?.[0]?.__typename !== 'Course' &&
    courses?.every(
      (course) =>
        course['pa_booking-time-slot'] &&
        course['pa_booking-time-slot'].length > 0
    )
  const isAllPaDuration =
    courses?.[0]?.__typename !== 'Course' &&
    courses?.every(
      (course) => course?.pa_duration && course?.pa_duration?.length > 0
    )
  const isAllPaFormat =
    courses?.[0]?.__typename !== 'Course' &&
    courses?.every(
      (course) => course?.pa_format && course?.pa_format?.length > 0
    )
  const isLanguage =
    courses?.[0]?.ProgramFields?.language ||
    courses?.[0]?.CourseFields?.language

  const handleButtonClick = (index: number) => {
    const rowRef = rowRefs.current[index]

    if (rowRef) {
      const link = rowRef.querySelector('a')
      if (link && link.ariaDisabled !== 'true') {
        link.click()
        rowRef.style.pointerEvents = 'none'
      }
    }
  }

  const coursesElements = (courses: any[]) =>
    courses?.length > 0 &&
    courses?.map((course, index) => (
      <tr
        className={cn(
          styles.subcategoryWrapper,
          isSafariBrowser ? styles.removeGreenUnderline : '',
          isCheckout ? styles.isCheckout : ''
        )}
        key={index}
        ref={(el) => (rowRefs.current[index] = el)}
        onClick={() => hasVariations && handleButtonClick(index)}
      >
        {!hasVariations ? (
          <Link href={course.uri}>
            <a
              className={styles.subcategoryWrapper}
              style={{
                animationDelay: `${
                  index < defaultShown
                    ? index * 0.1
                    : (index - defaultShown) * 0.1
                }s`
              }}
            >
              <div className={styles.subcategoryContainer}>
                <div className={styles.titleWrapper}>
                  <SubtitleWithDot
                    word1={
                      course.productCategories?.nodes[0]?.name ||
                      course.courseCategorys?.nodes[0]?.name
                    }
                    word2={
                      course.ProgramFields?.diploma ||
                      course.CourseFields?.diploma
                    }
                    className={styles.subtitleWrapper}
                  />
                  <div className={styles.subheading}>
                    {course.name || course.title}
                  </div>
                </div>
                {isAllPaDates && (
                  <div
                    className={styles.textWrapper}
                    dangerouslySetInnerHTML={{
                      __html:
                        course.pa_dates?.replace(/-/g, replaceDash) ||
                        course?.CourseFields?.nextCourse?.replace(
                          /-/g,
                          replaceDash
                        )
                    }}
                  />
                )}
                {isAllPaDuration && (
                  <div
                    className={styles.textWrapper}
                    dangerouslySetInnerHTML={{
                      __html:
                        course.pa_duration?.replace(/-/g, replaceDash) ||
                        course?.CourseFields?.duration?.replace(
                          /-/g,
                          replaceDash
                        )
                    }}
                  />
                )}
                <div className={styles.textWrapper}>
                  {course.ProgramFields?.format || course.CourseFields?.format}
                </div>
                {isLanguage && (
                  <div className={styles.textWrapper}>
                    {course?.ProgramFields?.language ||
                      course?.CourseFields?.language}
                  </div>
                )}
                {!isCheckout && (
                  <div className={styles.textWrapper}>
                    <span>{t('global:priceLabel')}</span>
                    {course.ProgramFields?.price ||
                      course.CourseFields?.language}
                  </div>
                )}
              </div>
              <div className={styles.arrowWrapper}>
                <Icon icon="arrowRight" size={32} sizeViewbox={32} />
              </div>
            </a>
          </Link>
        ) : (
          course?.variationPrice && (
            <>
              <td className={styles.titleWrapper}>
                {course.name || course.title}
                {course?.variationDescription &&
                  course?.variationDescription.length > 0 && (
                    <span className={styles.variationDescription}>
                      {course?.variationDescription}
                    </span>
                  )}
              </td>
              {isAllPaDates && (
                <td
                  className={styles.textWrapper}
                  dangerouslySetInnerHTML={{
                    __html: `<span>${t('global:timeLabel')}</span>${
                      course.pa_dates?.replace(/-/g, replaceDash) ||
                      course?.CourseFields?.nextCourse?.replace(
                        /-/g,
                        replaceDash
                      )
                    }`
                  }}
                />
              )}
              {isAllPaDuration && (
                <td
                  className={styles.textWrapper}
                  dangerouslySetInnerHTML={{
                    __html: `<span>${t('labelMapping:duration')}</span>${
                      course.pa_duration?.replace(/-/g, replaceDash) ||
                      course?.CourseFields?.duration?.replace(/-/g, replaceDash)
                    }`
                  }}
                />
              )}
              {isAllPaFormat && (
                <td className={styles.textWrapper}>
                  <span>{t('labelMapping:format')}</span>
                  {course.pa_format || course.CourseFields?.format}
                </td>
              )}
              {!isCheckout && (
                <td
                  className={styles.textWrapper}
                  dangerouslySetInnerHTML={{
                    __html: `<span>${t('global:priceLabel')}</span>${
                      course?.variationPrice ||
                      course.CourseFields?.participationFee
                    }`
                  }}
                />
              )}
              {!isCheckout &&
                course?.variationNote &&
                course?.variationNote.length > 0 && (
                  <td className={styles.textWrapper}>
                    {course?.variationNote}
                  </td>
                )}
              {isAllPaBookingTimeSlot && (
                <td className={styles.textWrapper}>
                  <span>{t('labelMapping:bookingTimeSlot')}</span>
                  {course['pa_booking-time-slot']}
                </td>
              )}
              {isLanguage && (
                <td
                  className={cn(
                    styles.textWrapper,
                    post?.language?.code === 'EN' ? styles.isBold : ''
                  )}
                >
                  {post?.language?.code === 'EN' && (
                    <Icon icon="error_outline" size={20} sizeViewbox={24} />
                  )}
                  <span>{t('labelMapping:language')}</span>
                  {course?.ProgramFields?.language ||
                    course?.CourseFields?.language}
                </td>
              )}
              {isCheckout ? (
                <td className={styles.price}>
                  <span>{t('global:priceLabel')}</span>
                  {course?.variationPrice.replace('&nbsp;', ' ')}
                </td>
              ) : course.variationId && !course.variationOfferNotAvailable ? (
                <AddToCart
                  isSmall
                  product={course}
                  title={t('programm:applyNowButton')}
                  removeIcon
                />
              ) : null}
            </>
          )
        )}
      </tr>
    ))

  useEffect(() => {
    setIsTabChange(true)
    setTimeout(() => {
      setIsTabChange(false)
    }, 100)
  }, [courses])

  useEffect(() => {
    const isSafari = () => {
      setIsSafariBrowser(
        isLg && /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      )
    }

    window.addEventListener('resize', isSafari)

    return () => {
      window.removeEventListener('resize', isSafari)
    }
  }, [isLg])

  return (
    <>
      <table
        className={cn(
          styles.categoriesWrapper,
          isCheckout ? styles.isCheckout : ''
        )}
        width="100%"
      >
        <tr
          className={cn(
            styles.header,
            isCheckout ? styles.isCheckout : '',
            isSafariBrowser ? styles.removeGreenUnderline : ''
          )}
        >
          <th>{t('global:programTitleLabel')}</th>
          {isAllPaDates && <th>{t('global:timeLabel')}</th>}
          {isAllPaDuration && <th>{t('labelMapping:duration')}</th>}
          {isAllPaFormat && <th>{t('labelMapping:format')}</th>}
          {!isCheckout && <th>{t('global:priceLabel')}</th>}
          {isAllPaBookingTimeSlot && (
            <th>{t('labelMapping:bookingTimeSlot')}</th>
          )}
          {isLanguage && <th>{t('labelMapping:language')}</th>}
        </tr>
        {isTabChange && !isCheckout
          ? []
          : coursesElements(
              showMore ? courses.slice(0, defaultShown) : courses
            )}
      </table>
    </>
  )
}
